<template>
  <div class="list-expandable">
    <div class="list-expandable_pan">
      <span class="list-expandable_pan-title">
        {{ title }}
      </span>

      <div class="list-expandable_pan-act">
        <v-btn icon @click="onExpand">
          <v-icon v-if="!expand">
            mdi-plus
          </v-icon>
          <v-icon v-else>
            mdi-minus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div :class="['list-expandable_child', { 'active': expand }]">
      <div class="list-expandable_child-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListExpandable',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expand: false
    }
  },
  methods: {
    onExpand() {
      this.expand = !this.expand
    }
  }
}
</script>
<style lang="scss" scoped>
  .list-expandable {
    background-color: #F5F5F5;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;

    &_pan {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      &-title {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &_child {
      height: 0px;
      opacity: 0;
      transition: all .2s ease-out;
      &-text {
        padding: 8px 16px;
        font-size: 14px;
      }
      &.active {
        height: auto;
        transition: all .2s ease-in;
        opacity: 1;
      }
    }
  }
</style>
