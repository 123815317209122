<template>
  <v-dialog
    ref="refDialogLiveTracking"
    v-model="open"
    fullscreen
    transition="dialog-bottom-transition"
    @click:outside="handleClickOutside"
    @keydown="handleKeydown"
  >
    <v-card class="dlt-wrapper">
      <div class="dlt-navigation-pane dlt-navigation-pane--shadow">
        <span class="dlt-navigation-pane_title">{{ $t('order.live_tracking') }}</span>
        <v-btn icon @click="onClose">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <iframe
        class="dlt-tracking-map container px-0 py-0 col-lg-3 col-md-6 col-sm-6 col-12"
        :src="liveTrackingUrl || ''"
        loading="lazy"
      />
      <div class="dlt-courier-info-wrapper container px-0 py-0 col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="dlt-courier-info">
          <CourierCard
            :driver-name="driverName"
            :driver-phone="driverPhone"
            :vehicle-number="vehicleNumber"
            :vehicle-type="vehicleType"
            :status-shipper="statusShipper"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import CourierCard from './CourierCard.vue'

export default {
  name: 'DialogLiveTracking',
  components: {
    CourierCard
  },
  props: {
    open: {
      default: false,
      type: Boolean
    },
    onClose: {
      default: () => console.log('close'),
      type: Function
    },
    driverName: {
      default: '',
      type: String
    },
    driverPhone: {
      default: '',
      type: String
    },
    vehicleNumber: {
      default: '',
      type: String
    },
    vehicleType: {
      default: '',
      type: String
    },
    statusShipper: {
      default: '',
      type: String
    },
    liveTrackingUrl: {
      default: '',
      type: String
    }
  },
  methods: {
    handleClickOutside(e) {
      const isPersistend = this.$refs.refDialogLiveTracking.persistent
      if (!isPersistend) {
        this.$props.onClose()
      }
    },
    handleKeydown(e) {
      if (e.code === 'Escape' && e.keyCode === 27) {
        this.$props.onClose()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dlt-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.dlt-navigation-pane {
  display: flex;
  padding: 14.5px 16px;
  justify-content: space-between;
  align-items: center;
  &_title {
    font-size: 20px;
    font-weight: 700;
  }
}
.dlt-navigation-pane.dlt-navigation-pane--shadow {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.122);
}
.dlt-tracking-map {
  flex: 1;
  border: 0;
}
.dlt-courier-info-wrapper {
  position: relative;
}
.dlt-courier-info {
  position: absolute;
  width: 100%;
  padding: 16px 16px;
  bottom: 0;
}
</style>
