<template lang="html">
  <div :class="[{ 'status-is-open' : isOpenStatus }]">
    <div class="overlay" />
    <v-app-bar app flat color="white" class="app-bar-no-padding">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-btn icon class="mr-2" @click="goBack">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <div class="font-18 font-weight-bold">
                {{ order.order_no }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <div v-if="order.status_inquiry === 2" class="banner-store mb-2">
      <v-img :src="require('@/assets/img/png/store.png')" height="120" contain style="padding-top: 82px;" />
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" style="margin-bottom: 72px">
          <!-- <v-card v-if="order.status_inquiry === 2" class="card-border pa-4 mb-2" style="background-color: #FFF2E3;">
            <div class="d-flex align-center">
              <div class="list-content">
                <div class="list-title font-18 font-weight-bold">
                  {{ order.status_desc }}...
                </div>
              </div>
            </div>
          </v-card> -->
          <v-card v-if="order.status === 'P'" class="card-border mb-2 px-2 py-4">
            <div class="d-flex align-center">
              <v-icon color="warning" class="mr-2">
                mdi-information
              </v-icon>
              <div class="list-content">
                <div class="list-title font-18 font-weight-bold">
                  {{ order.status_desc }}
                </div>
              </div>
              <v-btn v-if="order.payment_mode_name === 'Xendit Virtual Bank' && order.is_paid === 0" :to="{ name: 'va_payment' }" color="warning" height="40" class="soft-shadow text-none font-weight-bold border-10 letter-space-0">
                {{ $t('order.payment') }}
              </v-btn>
            </div>
          </v-card>
          <div v-if="order.status_inquiry !== 1 && order.status_inquiry !== 2 && order.status_inquiry !== 7" class="mb-4">
            <div class="status-order-stepper">
              <div class="status-order-stepper-item">
                <div :class="['status-order-stepper-icon', { 'primary': order.status_inquiry === 3 || order.status === 'S' || order.status === 'T' || order.status === 'R' || order.status === 'Z' }]">
                  <v-icon size="18" color="white">
                    mdi-reload
                  </v-icon>
                </div>
                <div class="status-order-stepper-text">
                  {{ $t('order.processed') }}
                </div>
              </div>
              <hr :class="[`line-order-stepper`, { 'primary': order.status === 'S' || order.status === 'T' || order.status === 'R' || order.status === 'Z' }]">
              <div class="status-order-stepper-item">
                <div :class="[`status-order-stepper-icon`, { 'primary': order.status === 'S' || order.status === 'T' || order.status === 'R' || order.status === 'Z' }]">
                  <v-icon v-if="order.remark === 'SELF PICK-UP'" size="18" color="white">
                    mdi-store
                  </v-icon>
                  <v-icon v-else size="18" color="white">
                    mdi-truck
                  </v-icon>
                </div>
                <div v-if="order.remark === 'SELF PICK-UP'" class="status-order-stepper-text">
                  {{ $t('order.ready_to_pickup') }}
                </div>
                <div v-else class="status-order-stepper-text">
                  {{ $t('order.sent') }}
                </div>
              </div>
              <hr :class="[`line-order-stepper`, { 'primary': order.status === 'T' || order.status === 'R' || order.status === 'Z' }]">
              <div class="status-order-stepper-item">
                <div :class="[`status-order-stepper-icon`, { 'primary': order.status === 'T' || order.status === 'R' || order.status === 'Z' }]">
                  <v-icon size="18" color="white">
                    mdi-package-variant-closed
                  </v-icon>
                </div>
                <div v-if="order.remark === 'SELF PICK-UP'" class="status-order-stepper-text">
                  {{ $t('order.picked_up') }}
                </div>
                <div v-else class="status-order-stepper-text">
                  {{ $t('order.received') }}
                </div>
              </div>
              <hr :class="[`line-order-stepper`, { 'primary': order.status === 'Z' && order.is_paid === 1 }]">
              <div class="status-order-stepper-item">
                <div :class="[`status-order-stepper-icon`, { 'success': order.status === 'Z' && order.is_paid === 1 }]">
                  <v-icon size="18" color="white">
                    mdi-check-bold
                  </v-icon>
                </div>
                <div class="status-order-stepper-text">
                  {{ $t('order.finished') }}
                </div>
              </div>
            </div>
          </div>

          <template v-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '2'">
            <div v-if="order.shipping_log.shipper.status === 'inactive' && order.status_mark === 'processed'" class="d-flex bg-alert-warning-soft px-4 py-4 rounded-lg mb-4">
              <div class="mr-4 d-flex align-center">
                <img src="@/assets/img/png/icon_warning.png" alt="Warning" class="image-24">
              </div>
              <div class="font-14 line-height-normal">
                {{ $t('order.information_shipper_pending_process', { shipper: order.shipping_courier_name }) }}
              </div>
            </div>
          </template>

          <div v-if="order.status_inquiry === 7" class="order-cancelled">
            <v-icon color="white">
              mdi-close-circle
            </v-icon>
            <div class="order-cancel">
              {{ $t('order.status.cancelled') }}
            </div>
          </div>
          <!-- Gratis Antar -->
          <template v-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '1'">
            <div v-if="order.status_mark === 'processed' || order.status_mark === 'shipping'" class="mb-4">
              <OutletCallCardVue
                :order-status="order.status_desc"
                @handle-call="goToWhatsAppOrCall"
              />
            </div>
          </template>
          <!-- Ambil Sendiri -->
          <template v-if="order.remark === 'SELF PICK-UP'">
            <div v-if="order.status_mark === 'processed' || order.status_mark === 'ready_to_pickup'" class="mb-4">
              <OutletCallCardVue
                :order-status="order.status_desc"
                @handle-call="goToWhatsAppOrCall"
              />
            </div>
          </template>
          <!-- Shipper -->
          <template v-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '2'">
            <div v-if="order.status_mark === 'processed'" class="mb-4">
              <OutletCallCardVue
                :order-status="order.status_desc"
                @handle-call="goToWhatsAppOrCall"
              />
            </div>
            <div v-if="order.status_mark === 'shipping' && isInstantOrSameDayShippingService" class="mb-4">
              <CourierCard
                :driver-name="shipper.driverName"
                :driver-phone="shipper.driverPhone"
                :vehicle-type="shipper.vehicleType"
                :vehicle-number="shipper.vehicleNumber"
                :status-shipper="shipper.statusShipper"
                :is-driver="shipper.isDriver"
                :hide-info="true"
              />
            </div>
          </template>
          <div class="d-flex align-center pa-1 mb-3">
            <div class="d-flex mr-4">
              <img :src="require('@/assets/img/svg/icon-history.svg')" alt="">
            </div>
            <div class="list-content">
              <div class="list-title font-14 font-weight-bold">
                <span v-if="!$isEmpty(order.translogs) && order.translogs.length > 0 && order.status !== 'X'">
                  <template v-if="order.is_paid === 1 && order.status === 'P'">
                    {{ $t('order.status.is_paid') }}
                  </template>
                  <template v-else-if="order.is_paid === 1">
                    {{ order.status_desc }}
                  </template>
                  <template v-else-if="order.is_paid === 0">
                    {{ $t('order.status.bought') }}
                  </template>
                </span>
                <span v-if="order.status === 'X'">
                  {{ $t('order.status.cancelled') }}
                </span>
                <span v-if="$isEmpty(order.translogs) && order.translogs.length === 0 && order.status !== 'X' && order.is_paid === 0">
                  {{ $t('order.status.bought') }}
                </span>
                <span v-if="$isEmpty(order.translogs) && order.translogs.length === 0 && order.status !== 'X' && order.is_paid === 1">
                  {{ $t('order.status.is_paid') }}
                </span>
              </div>
              <div class="list-subtitle font-12 grey-color-text">
                <span v-if="order.translogs.length < 1 || order.status === 'P'">
                  {{ order.forder_date }}, {{ order.forder_time }}
                </span>
                <span v-else>
                  {{ order.fmodified_time }}
                </span>
              </div>
            </div>
            <v-btn text color="primary" class="text-none font-weight-bold letter-space-0" @click="isOpenStatus = true">
              {{ $t('order.view_detail') }}
            </v-btn>
          </div>
          <v-card v-if="order.remark === 'SELF PICK-UP'" class="card-border mb-2 px-2 py-3">
            <div class="card-pickup mb-4">
              <div class="d-flex">
                <div class="icon-pickup mr-2">
                  <img :src="require('@/assets/img/png/pickup.png')" alt="">
                </div>
                <div class="list-content">
                  <div class="list-title font-18 font-weight-bold black--text">
                    {{ $t('product.take_by_yourself') }}
                  </div>
                </div>
              </div>
              <v-spacer />
              <div class="d-flex">
                <div class="list-content mr-2">
                  <div class="list-title font-18 font-weight-bold black--text">
                    {{ selected_store.name }}
                  </div>
                </div>
                <div class="icon-store-pickup">
                  <img :src="selected_store.logo" :alt="order.store_name">
                </div>
              </div>
            </div>
            <div v-if="!$isEmpty(selected_store)" class="card-pickup-store mb-2">
              <div class="d-flex align-center">
                <div class="list-content">
                  <div class="list-subtitle font-14 text-two-line">
                    <b>{{ outletDistance }} km</b> {{ $t('order.from_your_location') }} &#x2022; {{ selected_store.address }}
                  </div>
                </div>
                <div class="ml-2">
                  <input id="store_address" type="hidden" :value="selected_store.address">
                  <v-btn icon @click="copyText('#store_address')">
                    <v-icon>
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="!$isEmpty(selected_store)" class="card-pickup-store d-flex justify-space-between" @click="openOperationalHours">
              <div class="d-flex align-center">
                <div class="list-content">
                  <div class="list-title font-14 font-weight-bold">
                    <span v-if="selected_store.store_is_open" class="isOpen">
                      {{ $t('layout.open') }}
                    </span>
                    <span v-else class="black--text">
                      {{ $t('layout.close') }}
                    </span>
                  </div>
                  <div v-if="selected_store.shop_hours_today.length > 0" class="list-subtitle font-14 text-two-line" v-html="workingStore" />
                </div>
              </div>
              <v-icon size="20">
                mdi-chevron-right
              </v-icon>
            </div>
          </v-card>
          <template v-else>
            <v-card v-if="order.shipping_delivery_type === '1' || order.shipping_delivery_type === '2'" class="px-0 py-0 card-border mb-4">
              <div class="card-pickup px-4 py-3">
                <div class="icon-pickup mr-2">
                  <img
                    v-if="order.shipping_delivery_type === '1'"
                    :src="require('@/assets/img/png/free_delivery.png')"
                    :alt="order.shipping_courier_name"
                  >
                  <img
                    v-if="order.shipping_delivery_type === '2'"
                    :src="order.shipping_log.shipper.courier.image_url"
                    :alt="order.shipping_courier_name"
                  >
                </div>
                <div class="list-content">
                  <div class="list-title font-18 font-weight-bold black--text">
                    <span v-if="order.shipping_delivery_type === '1'">
                      {{ $t('global.free_shipping') }}
                    </span>
                    <span v-if="order.shipping_delivery_type === '2'">
                      {{ order.shipping_courier_name }}
                    </span>
                  </div>
                  <div v-if="order.shipping_delivery_type === '2'" class="font-12 grey-color-text">
                    {{ order.shipping_service_type }}
                  </div>
                </div>
                <div class="list-content text-right">
                  <div class="list-subtitle font-12 grey-color-text">
                    {{ $t('order.additional_cost') }}
                  </div>
                  <div class="list-title font-18 font-weight-bold black--text">
                    <span v-if="Number(order.shipping_cost) > 0">+ <span v-mask-money="order.shipping_cost" /></span>
                    <span v-else>{{ $t('product.free') }}</span>
                  </div>
                </div>
              </div>
              <div v-if="isRegularShippingService" class="px-4 py-3 d-flex align-center justify-space-between grey lighten-4">
                <div class="d-flex flex-column">
                  <span class="font-14 font-weight-bold">{{ order?.shipping_log?.shipper?.order?.awb_number || '-' }}</span>
                  <span class="list-subtitle font-12 grey-color-text">No. Resi</span>
                </div>
                <v-btn class="ml-2" text icon color="primary" @click="copyText('#no_resi')">
                  <input id="no_resi" type="hidden" :value="order?.shipping_log?.shipper?.order?.awb_number">
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </div>
            </v-card>
            <v-card v-if="order.shipping_cost > 0 && order.remark === 'DELIVERY' && order.shipping_delivery_type === '1'" class="mb-4 free-shipping-exceed">
              {{ $t('order.distance_exceeded') }} ({{ order.shipping_radius_outside }} km). {{ $t('order.free_shipping_additional_cost') }} {{ $store.state.outlet.selected_outlet.delivery.fcost_outside_radius }}/km
            </v-card>
            <v-card class="card-border mb-2 pa-4">
              <div class="card-pickup mb-4">
                <div class="font-18 font-weight-bold black--text mr-4">
                  {{ $t('order.shipping_address') }}
                </div>
                <div class="list-content text-right">
                  <div class="list-title font-18 font-weight-bold black--text">
                    {{ order.customer_name }}
                  </div>
                </div>
              </div>
              <div class="card-pickup mb-2">
                <div class="list-content">
                  <div class="list-title font-12 black--text text-two-line">
                    {{ order.shipping_address }}
                  </div>
                </div>
                <div class="ml-2 font-12 grey-color-text">
                  {{ order.shipping_radius === 0 ? 1 : order.shipping_radius }} km
                </div>
              </div>
              <v-text-field outlined prepend-inner-icon="mdi-note-text" disabled readonly dense hide-details :value="order.shipping_note" />
            </v-card>
          </template>
          <v-card class="card-border mt-4 pa-4">
            <div v-for="(item, idx) in order.items" :key="idx" class="d-flex card-order">
              <div class="card-order-item-qty">
                {{ item.qty }}x
              </div>
              <div class="card-order-item-content">
                <div class="card-order-item-title">
                  {{ item.item_name }}
                </div>
                <div class="card-order-item">
                  <div class="card-order-item-image">
                    <img v-if="item.product_combo_id === 0" :src="item.product_photo_md" :alt="item.item_name">
                    <img v-else :src="item.product_combo_photo_md" :alt="item.item_name">
                  </div>
                  <div class="card-order-item-detail">
                    <div class="card-order-item-weight">
                      {{ item.total_weight }} kg
                    </div>
                    <div class="card-order-item-price">
                      <span v-mask-money="item.price" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-card v-if="order.notes !== ''" class="card-border mt-4 pa-4">
            <v-text-field outlined prepend-inner-icon="mdi-note-text" readonly disable dense hide-details v-html="html_order_notes" />
          </v-card>
          <v-card v-if="!$isEmpty(order)" class="card-border mt-4 pa-0">
            <div class="list-price-detail">
              <div class="list-price-item-detail">
                <div>{{ $t('product.sub_total') }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  ({{ stockInOrderSummary }} {{ $t('product.product') }})
                  <span v-mask-money-no-currency="order.order_amount" />
                </div>
              </div>
              <div v-if="Number(order.discount_sales_amount) > 0" class="list-price-item-detail">
                <div>{{ $t('product.discount') }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-if="Number(order.discount_sales_amount) > 0" class="font-discount">-</span>
                  <span v-mask-money-no-currency="order.discount_sales_amount" :class="[{ 'font-discount': Number(order.discount_sales_amount) > 0 }]" />
                </div>
              </div>
              <div v-if="Number(order.discount_amount) > 0" class="list-price-item-detail">
                <div>Voucher</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-if="Number(order.discount_amount) > 0" class="font-discount">-</span>
                  <span v-mask-money-no-currency="order.discount_amount" :class="[{ 'font-discount': Number(order.discount_amount) > 0 }]" />
                </div>
              </div>
              <div class="list-price-item-detail">
                <div>{{ $t('product.conv_cost') }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-mask-money-no-currency="Number(order.trans_fee_amount)" />
                </div>
              </div>
              <div class="list-price-item-detail">
                <div>{{ $t('product.service_cost') }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-mask-money-no-currency="order.service_charge_amount" />
                </div>
              </div>
              <div v-if="Number(order.tax_amount) > 0" class="list-price-item-detail">
                <div>PPN</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-mask-money-no-currency="order.tax_amount" />
                </div>
              </div>
              <div class="list-price-item-detail">
                <div>{{ $t('product.shipping_cost') }} {{ order.shipping_service_type === 'Regular' ? order.shipping_log.shipper.order.use_insurance ? `(+${$t('order.insurance')})` : '' : '' }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-if="Number(order.shipping_cost) > 0" v-mask-money-no-currency="order.shipping_cost" />
                  <span v-else>
                    {{ $t('product.free') }}
                  </span>
                </div>
              </div>
              <!-- <template v-if="order.shipping_service_type !== 'Instant' || order.shipping_service_type !== 'Same Day'">
                <div v-if="order.shipping_log.shipper.order.use_insurance" class="list-price-item-detail">
                  <div>{{ $t('order.shipping_insurance') }}</div>
                  <v-spacer />
                  <div class="font-weight-bold">
                    <span v-mask-money-no-currency="order.shipping_log.shipper.order.insurance_fee" />
                  </div>
                </div>
              </template> -->
              <div v-if="Number(order.rounded_amount) < 0 || Number(order.rounded_amount) > 0 " class="list-item-order">
                <div class="item-order-title">
                  {{ $t('order.rounding') }}
                </div>
                <v-spacer />
                <div class="item-order-desc">
                  <span v-mask-money-no-currency="order.rounded_amount" />
                </div>
              </div>
              <div class="list-price-item-detail">
                <div>{{ $t('product.total_payment') }}</div>
                <v-spacer />
                <div class="font-weight-bold">
                  <span v-mask-money-no-currency="order.total_amount" />
                </div>
              </div>
            </div>
          </v-card>
          <!-- <div v-if="Number(data_calculation.point_earn_expected) > 0" class="mt-4">
            <div class="container-point-earn-expected" v-html="$t('product.potential_to_get_points', { point: Number(data_calculation.point_earn_expected) })" />
          </div> -->
          <div class="order-payment-method mt-4">
            <div v-if="order.payment_log && !$isEmpty(order.payment_log)">
              <v-img :src="order.payment_log.payment_image" contain width="50" height="28" :alt="order.payment_log.payment_type" />
            </div>
            <div class="order-payment-method-content">
              <div class="order-payment-method-subtitle">
                {{ $t('product.payment_method') }}
              </div>
              <div v-if="!order.payment_log" class="order-payment-method-title">
                {{ order.payment_mode_name }}
              </div>
              <div v-else class="order-payment-method-title">
                {{ order.payment_log.payment_type }}
              </div>
            </div>
          </div>
          <v-btn v-if="order.status === 'P' && order.is_paid === 0" color="#F44336" height="40" text block class="text-none mt-6 font-weight-bold" @click="dialog.cancel_order = true">
            {{ $t('dialog.cancel_order') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div ref="detailStatus" :class="[`status-fixed`, { 'active': isOpenStatus }]">
      <div class="sheet-bar">
        <div class="sheet-bar-content">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center">
                  <v-btn icon class="mr-2" @click="isOpenStatus = !isOpenStatus">
                    <v-icon>
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                  <div class="font-18 font-weight-bold">
                    {{ $t('order.log_order') }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="px-3">
              <div class="">
                <div v-for="(item, idx) in arrLog" :key="idx" class="logs-item">
                  <div class="logs-item-header">
                    <div class="logs-item-header-icon">
                      <v-icon v-if="idx == 0" color="#6EBE46">
                        mdi-check-circle
                      </v-icon>
                      <div v-else class="logs-item-header-icon-bg" />
                    </div>
                    <div class="logs-item-header-content">
                      <div class="logs-item-header-title">
                        {{ item.action_by_name }}
                      </div>
                    </div>
                    <div class="logs-item-header-action">
                      <div class="logs-item-header-action-text">
                        {{ item.faction_time }}
                      </div>
                    </div>
                  </div>
                  <div class="logs-item-body">
                    <div class="logs-item-body-icon">
                      <div v-if="idx !== (order.translogs.length - 1)" class="logs-item-body-icon-line" />
                    </div>
                    <div class="logs-item-body-content">
                      <div class="logs-item-body-content-text">
                        {{ item.action }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="dialog.cancel_order" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialog.confirm') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dialog.are_you_sure_want_to_cancel_the_order') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" text class="text-none" @click="dialog.cancel_order = false">
            No
          </v-btn>
          <v-btn color="primary" text class="text-none" @click="confirmCancel()">
            {{ $t('dialog.yes_cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div>
          {{ snackbar.text }}
        </div>
        <v-btn class="transparent" text fab x-small dark @click="snackbar.show = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </v-snackbar>

    <div v-if="order.status === 'X' || order.status === 'P' || order.status === 'A' || order.status === 'S' || order.status === 'Z' || order.status === 'T' || order.status === 'R'" :class="['menu-bottom', {'menu-bottom__live-tracking': isInstantOrSameDayShippingService && isLiveTrackingOn}]">
      <div class="wrap-menu-bottom">
        <v-container :class="['d-flex', { 'pa-0': $vuetify.breakpoint.width > 600 }]">
          <v-row :class="[{ 'ma-0 fill-height': $vuetify.breakpoint.width > 600 }]">
            <v-col cols="12" class="py-0" :class="[{ 'px-0': $vuetify.breakpoint.smAndDown }]">
              <div class="d-flex fill-height align-center justify-center">
                <!-- Menunggu Konfirmasi Toko -->
                <v-btn v-if="order.status_mark === 'waiting_confirmation_resto'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="goToWhatsAppOrCall()">
                  {{ $t('order.contact_seller') }}
                </v-btn>
                <!-- Gratis Antar -->
                <template v-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '1'">
                  <v-btn v-if="order.status_mark === 'waiting_for_payment'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                    {{ $t('order.reload') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'processed' || order.status_mark === 'shipping' || order.status_mark === 'shipped'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                    {{ $t('order.reload') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'completed' || order.status_mark === 'cancelled'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="repeatOrder()">
                    {{ $t('order.repeat') }}
                  </v-btn>
                </template>
                <!-- Ambil Sendiri -->
                <template v-if="order.remark === 'SELF PICK-UP'">
                  <v-btn v-if="order.status_mark === 'waiting_for_payment'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                    {{ $t('order.reload') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'processed' || order.status_mark === 'picked_up'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                    {{ $t('order.reload') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'ready_to_pickup'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="goToGoogleMaps()">
                    {{ $t('order.store_location') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'completed' || order.status_mark === 'cancelled'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="repeatOrder()">
                    {{ $t('order.repeat') }}
                  </v-btn>
                </template>
                <!-- Shipper -->
                <template v-if="order.remark === 'DELIVERY' && order.shipping_delivery_type === '2'">
                  <v-btn v-if="order.status_mark === 'waiting_for_payment'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                    {{ $t('order.reload') }}
                  </v-btn>
                  <v-btn v-if="order.status_mark === 'processed'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="goToWhatsAppOrCall()">
                    {{ $t('order.contact_seller') }}
                  </v-btn>
                  <template v-if="isInstantOrSameDayShippingService">
                    <template v-if="isLiveTrackingOn">
                      <v-btn v-if="order.status_mark === 'shipping'" tile depressed block height="100%" :class="['text-none', 'btn-order-bottom', { 'btn-order-bottom__live-tracking': isLiveTrackingOn }]" @click="handleOpenLiveTracking()">
                        {{ $t('order.live_tracking') }}
                      </v-btn>
                      <v-btn v-if="order.status_mark === 'shipped'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                        {{ $t('order.reload') }}
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn v-if="order.status_mark === 'shipping' || order.status_mark === 'shipped'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                        {{ $t('order.reload') }}
                      </v-btn>
                    </template>
                  </template>
                  <template v-else>
                    <v-btn v-if="order.status_mark === 'shipping'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="goToWhatsAppOrCall()">
                      {{ $t('order.contact_seller') }}
                    </v-btn>
                    <v-btn v-if="order.status_mark === 'shipped'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="handleRelaod()">
                      {{ $t('order.reload') }}
                    </v-btn>
                  </template>
                  <v-btn v-if="order.status_mark === 'completed' || order.status_mark === 'cancelled'" tile depressed block height="100%" class="text-none btn-order-bottom" @click="repeatOrder()">
                    {{ $t('order.repeat') }}
                  </v-btn>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <dialog-shop-open-hours v-model="dialog.open_hours" />
    <template v-if="isLiveTrackingOn">
      <DialogLiveTracking
        :open="isOpenLiveTracking"
        :on-close="handleCloseLiveTracking"
        :driver-name="shipper.driverName"
        :driver-phone="shipper.driverPhone"
        :vehicle-type="shipper.vehicleType"
        :vehicle-number="shipper.vehicleNumber"
        :status-shipper="shipper.statusShipper"
        :live-tracking-url="shipper.liveTrackingUrl"
      />
    </template>

    <ConfirmationAlert
      :title="'Driver belum ditemukan'"
      :description="'Live Tracking saat ini tidak tersedia'"
      :open="isOpenNotHaveLiveTracking"
      :show-close-button="false"
      :show-confirm-button="false"
      :deny-button-text="'Oke'"
      :on-deny="handleCloseNotHaveLiveTracking"
    />
  </div>
</template>
<script>
import { getOutlet } from '@/api/outlet'
import { order, cancelOrder } from '@/api/order'
import dialogShopOpenHours from '@/components/order/DialogShopOpenHours'
import ConfirmationAlert from '@/components/alert/ConfirmationAlert.vue'

import {
  distanceMatrix as distanceMatrixMaps
} from '@/api/maps'
import CourierCard from '@/components/order/CourierCard.vue'
import OutletCallCardVue from '@/components/order/OutletCallCard.vue'
import DialogLiveTracking from '@/components/order/DialogLiveTracking.vue'
import io from 'socket.io-client'
import { formatingDateToDay } from '@/utils/helpers'
import { getNextWorkTime } from '@/utils/storeOperational'

export default {
  components: {
    dialogShopOpenHours,
    CourierCard,
    OutletCallCardVue,
    DialogLiveTracking,
    ConfirmationAlert
  },
  data() {
    return {
      isOpenStatus: false,
      order: {},
      selected_store: {},
      dialog: {
        cancel_order: false,
        open_hours: false
      },
      html_order_notes: '',
      snackbar: {
        show: false,
        text: ''
      },
      outletDistance: 0,
      timerInterval: null,
      distance: 0,
      shipper: {
        driverName: '',
        driverPhone: '',
        vehicleNumber: '',
        vehicleType: '',
        statusShipper: '',
        liveTrackingUrl: '',
        isDriver: 0
      },
      isLiveTrackingOn: false,
      isOpenLiveTracking: false,
      socketShipper: null,
      isOpenNotHaveLiveTracking: false
    }
  },
  computed: {
    workingStore() {
      if (!this.$isEmpty(this.selected_store)) {
        if (this.selected_store.shop_hours_today.length > 0) {
          const workingHour = this.getWorkingHour()
          if (this.selected_store.store_is_open) {
            return `${workingHour.workingStartTime} - ${workingHour.workingEndTime}`
          } else {
            return `${this.$t('layout.open')} ${workingHour.workingDay}${workingHour.workingStartTime ? `, ${workingHour.workingStartTime}` : ''}`
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    arrLog() {
      const data = []
      if (!this.$isEmpty(this.order)) {
        for (var i = this.order.translogs.length - 1; i >= 0; i--) {
          data.push(this.order.translogs[i])
        }
      }
      return data
    },
    stockInOrderSummary() {
      if (this.order.items.length > 0) {
        const uniqueIds = []
        const unique = this.order.items.filter(element => {
          const isDuplicate = uniqueIds.includes(element.product_id)
          if (!isDuplicate) {
            uniqueIds.push(element.product_id)
            return true
          }
        })
        return unique.length
      } else {
        return 0
      }
    },
    isRegularShippingService() {
      if (this.order) {
        return ['Regular'].includes(this.order.shipping_service_type)
      }
      return false
    },
    isInstantOrSameDayShippingService() {
      if (this.order) {
        return ['Instant', 'Same Day'].includes(this.order.shipping_service_type)
      }
      return false
    }
  },
  mounted() {
    (async() => {
      // Get Order
      await this.getOrder()

      // Socket Shipper
      if (
        (this.order.remark === 'DELIVERY' && this.order.shipping_delivery_type === '2') &&
          (this.order.is_paid === 1 && this.order.status !== 'P')
      ) {
        this.getShipperSocket()
      }
    })()
  },
  beforeDestroy() {
    if (this.socketShipper) {
      this.socketShipper.disconnect()
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'orders' }).catch(() => {})
    },
    async getOrder() {
      try {
        const res = await order(this.$route.params.id)
        const data = res.data.data
        this.order = data
        if (data.remark === 'DELIVERY' && data.shipping_delivery_type === '2' && this.order.status !== 'P') {
          const dataOrderShipping = data.shipping_log.shipper.order
          this.shipper = {
            ...this.shipper,
            driverName: dataOrderShipping.driver.name,
            driverPhone: dataOrderShipping.driver.phone,
            vehicleNumber: dataOrderShipping.driver.vehicle_number,
            vehicleType: dataOrderShipping.driver.vehicle_type,
            statusShipper: dataOrderShipping.status_description,
            liveTrackingUrl: dataOrderShipping.live_tracking || '',
            isDriver: dataOrderShipping.is_driver || 0
          }
        }
        await this.getStore(this.order.store_id)
        if (this.order.notes !== '') {
          this.html_order_notes = (this.order.notes).replace(/\n/g, '<br/>')
        }
        if (this.order.remark === 'SELF PICK-UP') {
          this.getDistanceFromStore()
        }
        if (!this.$isEmpty(this.order.payment_log)) {
          if (this.order.payment_log.expired_at && this.order.status_inquiry !== 7) {
            const time = this.order.payment_log.expired_at
            this.getExpiryTime(time)
          }
        }
      } catch (e) {
        this.order = {}
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    async getStore(val) {
      try {
        const { data } = await getOutlet(val)
        this.selected_store = data.data
      } catch (e) {
        this.selected_store = {}
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    async confirmCancel() {
      try {
        await cancelOrder(this.order.sales_order_id)
        this.$router.push({ name: 'orders' }).catch(() => {})
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    goToWhatsAppOrCall() {
      let chatWA = null
      if (!this.$isEmpty(this.$store.state.outlet.selected_outlet)) {
        if (!this.$isEmpty(this.$store.state.outlet.selected_outlet.setting)) {
          chatWA = this.$store.state.outlet.selected_outlet.setting.whatsapp
        }
        if (chatWA) {
          this.$goToWhatsApp(chatWA, 'window')
        } else {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: this.$t('account.whatsapp_not_been_set')
          })
        }
      }
    },
    goToGoogleMaps() {
      window.open('https://maps.google.com/?q=' + this.$store.state.outlet.selected_outlet.location_lat + ',' + this.$store.state.outlet.selected_outlet.location_lon)
    },
    repeatOrder() {
      if (this.order.items[0].product_id !== 0) {
        this.$router.push({ name: 'product_detail', params: { id: this.order.items[0].product_id }}).catch(() => {})
      }
      if (this.order.items[0].product_combo_id !== 0) {
        this.$router.push({ name: 'combo_detail', params: { id: this.order.items[0].product_combo_id }}).catch(() => {})
      }
    },
    copyText(id) {
      const testingCodeToCopy = document.querySelector(id)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'Copied Successfully' : 'Copy Failed'
        this.snackbar.show = true
        this.snackbar.text = msg
      } catch (err) {
        this.snackbar.show = true
        this.snackbar.text = 'Oops, unable to copy'
      }
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    async getDistanceFromStore() {
      if (!('geolocation' in navigator)) {
        console.log('Geolocation is not available.')
      }
      navigator.geolocation.getCurrentPosition((pos) => {
        this.getDistance(pos.coords.latitude, pos.coords.longitude)
      }, () => {
        this.$store.state.block_location = true
      })
    },
    async getDistance(latitude, longitude) {
      const currentLocations = []
      currentLocations.push({
        lat: Number(latitude),
        lon: Number(longitude)
      })
      const form = await {
        origin_lat: Number(this.$store.state.outlet.selected_outlet.location_lat),
        origin_lon: Number(this.$store.state.outlet.selected_outlet.location_lon),
        destination: currentLocations
      }
      await this.checkDistance(form)
    },
    async checkDistance(data_form) {
      try {
        const { data } = await distanceMatrixMaps(data_form)
        if (data.rows[0].elements[0].distance.value > 1000) {
          const value = Math.round(data.rows[0].elements[0].distance.value / 1000)
          this.outletDistance = Number(value.toFixed(0))
        } else {
          const value = Math.ceil(data.rows[0].elements[0].distance.value / 1000)
          this.outletDistance = Number(value.toFixed(0))
        }
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('dialog.out_of_range')
        })
      }
    },
    getExpiryTime(time) {
      var countDownDate = new Date(time).getTime()
      const _this = this
      this.timerInterval = setInterval(function() {
        var now = new Date().getTime()
        _this.distance = countDownDate - now
        if (_this.distance < 0) {
          if (_this.order.is_paid === 0) {
            _this.confirmCancel()
          }
          clearInterval(_this.timerInterval)
        }
      }, 1000)
    },
    openOperationalHours() {
      this.dialog.open_hours = true
    },
    handleRelaod() {
      this.getOrder()
    },
    handleOpenLiveTracking() {
      if (this.shipper.isDriver) {
        this.isOpenLiveTracking = true
      } else {
        this.isOpenNotHaveLiveTracking = true
      }
    },
    handleCloseLiveTracking() {
      this.isOpenLiveTracking = false
    },
    handleCloseNotHaveLiveTracking() {
      this.isOpenNotHaveLiveTracking = false
    },
    getShipperSocket() {
      if (this.order.status === 'S' && this.isInstantOrSameDayShippingService) {
        this.isLiveTrackingOn = true
      }
      const socketShipper = io(`${process.env.VUE_APP_SOCKET_BASE}/shipper`, {
        withCredentials: true,
        extraHeaders: {
          Authorization: process.env.VUE_APP_SOCKET_SHIPPER_KEY
        }
      })
      socketShipper.on('connect', () => {
        if (process.env.NODE_ENV === 'development') {
          console.log('connected to the shipper socket')
        }
      })
      socketShipper.on('connect_error', (err) => {
        if (process.env.NODE_ENV === 'development') {
          console.error('shipper socket error: ', err)
        }
      })
      socketShipper.on('shipper-olsera', (shipper) => {
        if (shipper && shipper.data && shipper.data.detail && shipper.data.detail.olsera_sales_order_id.toString() === this.order.sales_order_id.toString()) {
          const dataDetail = shipper.data.detail
          const dataSales = shipper.data.sales
          const dataDriver = dataDetail.driver
          // if (dataSales.self_order_status_mark === 'shipping' || dataSales.self_order_status_mark === 'shipped') {
          if (dataSales.self_order_status_mark === 'shipping' && this.isInstantOrSameDayShippingService) {
            this.isLiveTrackingOn = true
          } else {
            this.isLiveTrackingOn = false
          }
          this.shipper = {
            ...this.shipper,
            driverName: dataDriver.name,
            driverPhone: dataDriver.phone,
            vehicleNumber: dataDriver.vehicle_number,
            vehicleType: dataDriver.vehicle_type,
            liveTrackingUrl: dataDetail.live_tracking || '',
            statusShipper: dataDetail.status_description,
            isDriver: dataDetail.is_driver
          }
          this.order.status = dataSales.self_order_status
          this.order.status_inquiry = dataSales.self_order_status_inquiry
          this.order.status_mark = dataSales.self_order_status_mark
          this.order.status_desc = dataSales.self_order_status_desc
        }
        // }
      })
      socketShipper.on('disconnect', () => {
        this.isLiveTrackingOn = false
        if (process.env.NODE_ENV === 'development') {
          console.log('Disconnected from the shipper socket')
        }
      })
      this.socketShipper = socketShipper
    },
    getWorkingHour() {
      const selectedOutlet = this.selected_store
      const _getWorkTime = getNextWorkTime(selectedOutlet.store_open_date_tomorrow, selectedOutlet.store_closing_date_today)
      const workingStartTime = _getWorkTime.times[0].fstart_time
      const workingEndTime = _getWorkTime.times[0].fend_time
      const workingDay = formatingDateToDay(_getWorkTime.date)

      return {
        workingStartTime,
        workingEndTime,
        workingDay
      }
    }
  }
}
</script>
